<script setup lang="ts">
import { getCurrentInstance, onMounted } from "vue";
import { useOperationStore } from "@/stores/operation";
import { fileExists } from "@/libs/tools";

const operation = useOperationStore();
const currentVueInstance = getCurrentInstance();

onMounted(async () => {
  try {
    await operation?.getOperation();

    if (operation?.operationData) {
      const lang = operation?.operationData?.langs[0];

      const files = operation?.getFilesData;
      let savedLang = "";

      if (operation?.operationData?.langs.length > 1) {
        savedLang =
          localStorage.getItem("lang") ?? operation?.operationData?.langs[0];
      } else {
        savedLang = operation?.operationData?.langs[0];
      }
      if (
        currentVueInstance &&
        currentVueInstance.proxy &&
        operation &&
        lang !== undefined
      ) {
        currentVueInstance.proxy.$i18n.locale = savedLang ?? lang;

        document.title =
          operation?.operationData?.custom?.pagetitle ?? "......";

        const favicon = fileExists(files as unknown as unknown[], "favicon");
        const linkfav: HTMLLinkElement | null =
          document.querySelector("link[rel='icon']");
        if (linkfav && favicon && favicon.length > 0) {
          linkfav.href = (favicon[0] as { uri: string })?.uri || "/favicon.ico";
        }
      }
    }
  } catch (error) {
    console.error(error);
  }
});
</script>

<template>
  <div id="app">
    <router-view v-if="operation?.operationData" />
  </div>
</template>
